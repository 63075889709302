.profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.profile.macos.fullscreen,
.profile.macos {
    padding-top: 20px;
}

.profile.macos {
    padding-top: 20px;
}

.profile.fullscreen {
    padding-top: 90px;
}

.profile .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 850px;
    width: 100%;
    padding: 0px 10px 100px 10px;
    box-sizing: border-box;
}

@media (min-width: 600px) {
    .profile .container {
        padding: 0px 50px 100px 50px;
    }
}

.profile .field {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0 10px 0;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
}

.profile .fieldLabel {
    margin-bottom: 10px;
}

.profile .fieldLabel,
.profile .fieldValue {
    width: 100%;
}

.profile input {
    width: 100%;
    border-radius: 5px;
    border: 0;
    padding: 10px 10px;
    background: linear-gradient(to right, #142E31, #18373a);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
}

.profile .footer {
    width: 100%;
    max-height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 10px 30px 10px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(to right, #142E31, #18373a);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile .footer button {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
    margin-top: 0;
}

.profile .footerTabs {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
    flex: 1;
    width: 100%;
}

.profile .footerTabs button {
    flex: 1;
    padding: 10px 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.profile .footerTabs button:active {
    opacity: 0.5;
}

.profile .footerTabs button svg {
    font-size: 1.5rem;
    color: #FFF;
}

.profile .buyStars {
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 5px;
}

.profile .buyStars div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.profile button {
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    width: 100%;
    text-transform: uppercase;
    user-select: none;
}

.profile button:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.profile .balance .fieldValue {
    font-size: 1.5rem;
}

.profile .nickname {
    position: relative;
}

.profile .nickname button {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 30px;
    width: 70px;
    margin-top: 0;
    font-size: 0.9rem;
    line-height: 0.9rem;
}

.profile .errorMessage {
    color: rgba(255, 0, 0, 1);
    font-size: 0.9rem;
    margin-top: 5px;
}

.profile .buyStarsButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.profile .buyStarsButtons button {
    flex: 1;
}


.profile .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    overflow: hidden;
}

.profile .tabs div {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    position: relative;
    flex: 1;
    color: #ffffff7e;
}

.profile .tab.active {
    background-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
}

.profile .tabs div:not(:last-child):after {
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 24px;
    width: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.profile .tabs div span {
    margin-left: 5px;
}

.profile .bots {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
}

.profile .bots .bot {
    font-size: 0.9rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
}

.profile .bots .botDeletingText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.profile .bots .botDeleting .botContent {
    opacity: 0.25;
}

.profile .bots .botDeleting .botDeletingOverlay {
    left: 0;
    transition: left 3s ease-in-out;
}

.profile .bots .botDeletingText {
    display: none;
}

.profile .bots .botDeleting .botDeletingText {
    display: block;
    text-align: center;
    line-height: 70px;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.profile .bots .botDeletingOverlay {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.2);
}

.profile .bots .botOpened {
    background-color: rgba(255, 255, 255, 0.2);
}

.profile .bots .botActions {
    position: absolute;
    right: -130px;
    transition: right 0.3s ease-in-out;
    top: 0;
    width: 130px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.profile .bots .botActions button {
    margin: 0;
    height: 100%;
    border-radius: 0;
}

.profile .bots .botOpened .botActions {
    right: 0;
}

.profile .bots .botContent {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transition: left 0.3s ease-in-out;
}

.profile .bots .botOpened .botContent {
    position: relative;
    top: 0;
    left: -130px;
    width: 100%;
    height: 100%;
}

.profile .bots .bot:first-of-type {
    border-radius: 10px 10px 0 0;
}

.profile .bots .bot:last-child {
    border-radius: 0 0 10px 10px;
}

.profile .bots .bot:only-child {
    border-radius: 10px;
}

.profile .bots .bot:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.profile .bots .bot:active {
    background-color: rgba(255, 255, 255, 0.05);
}

.profile .bots .botHeader,
.profile .bots .botFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.profile .bots .botHeader div:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.profile .bots .botHeader div:last-child {
    flex: 1;
    text-align: right;
    margin-left: 10px;
}

.profile .bots .botHeader {
    margin-bottom: 15px;
}

.profile .error,
.profile .success {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    text-align: center;

    z-index: 99999;
    color: rgba(255, 255, 255, 1);
    padding: 10px;

    animation: popup 1s;
}

@keyframes popup {
    0% {
        transform: translateY(-100%) translateX(-50%);
    }

    100% {
        transform: translateY(0) translateX(-50%);
    }
}

.profile .error div,
.profile .success div {
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

.profile .error div {
    background-color: rgba(225, 0, 0, 1);
}

.profile .success div {
    background-color: rgba(0, 180, 0, 1);
}

.profile .language {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.profile .language select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.profile .language select {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 0;
    padding: 5px 10px;
    background: linear-gradient(to right, #142E31, #18373a);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 0.9rem;
    box-sizing: border-box;
    outline: none;
}

.profile .language select:disabled {
    opacity: 0.5;
}

.profile .visability.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.profile .visability label {
    margin: 0 10px;
}

.profile .switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
}

.profile .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.profile .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #adb5bd;
    transition: .4s;
    border-radius: 30px;
}

.profile .slider:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 20px;
    left: 0.27em;
    bottom: 0.25em;
    background-color: #adb5bd;
    transition: .4s;
}

.profile input:checked+.slider {
    background-color: #FFC107;
    border: 1px solid #FFC107;
}

.profile input:focus+.slider {
    box-shadow: 0 0 1px #FFC107;
}

.profile input:checked+.slider:before {
    transform: translateX(1.4em);
    background-color: #fff;
}

.profile .browsingLevel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.profile .browsingLevel div {
    flex: 1;
    cursor: pointer;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.1);
    text-align: center;
    font-size: 0.9rem;
}

.profile .browsingLevel div:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.profile .browsingLevel div.active {
    background-color: rgba(255, 255, 255, 0.2);
}

.profile .browsingLevel div:nth-child(2n) {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.profile .browsingLevel div:first-child {
    border-radius: 5px 0 0 5px;
}

.profile .browsingLevel div:last-child {
    border-radius: 0 5px 5px 0;
    border-right: none;
}
