.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 1.5rem;
}

.loader img {
    width: 100%;
}
