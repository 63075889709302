:root {
  --tg-theme-bg-color: #0a282c;
  --tg-theme-secondary-bg-color: #145056;
  --tg-safe-area-inset-top: 0px;
  --tg-safe-area-inset-bottom: 0px;
}

html, body {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  height: 100vh;
  overflow: hidden;
  background: #0a282c;
}

body {
  margin: 0;
  color: #FFF;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  justify-content: center;
}

.ReactTags__tags {
  position: relative;
  width: 100%;
}

.ReactTags__tag {
  font-size: 0.9rem;
  line-height: 25px;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  height: 25px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2px 10px 2px 10px;
}

.ReactTags__tagInput {
  position: relative;
  width: 100%;
}

.ReactTags__tagInput button {
  position: absolute;
  right: 5px;
  top: 7px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 25px;
  border: none;
  color: #FFFFFF;
  text-transform: uppercase;
  user-select: none;
}

.ReactTags__selected {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.ReactTags__remove {
  cursor: pointer;
  background: none;
  border: none;
  color: #FFFFFF;
  font-size: 0.8rem;
}

.ReactTags__error {
  font-size: 0.8rem;
  margin-top: 10px;
  position: relative;
  padding-left: 20px;
}

.ReactTags__error svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
}
