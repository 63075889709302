.chats {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.chats.macos.fullscreen,
.chats.macos {
    padding-top: 20px;
}

.chats.macos {
    padding-top: 20px;
}

.chats.fullscreen {
    padding-top: 90px;
}

.chats .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 850px;
    width: 100%;
    padding: 20px 10px 100px 10px;
    box-sizing: border-box;
}
/* 
@media (min-width: 600px) {
    .chats .container {
        padding: 0px 50px 100px 50px;
    }
} */

.chats .avatar {
    width: 100%;
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-size: 110% 110%;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.5);
    margin: 0 10px 0 0;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}

.chats .chatsList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.chats .chat {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-height: 80px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.chats .chat .info {
    display: flex;
    flex: 0 1 auto;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chats .chat .info .chatName,
.chats .chat .info .botName,
.chats .chat .info .messages {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chats .chat .actions {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chats .chat .actions .button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    color: #FFF;
    padding: 20px;
}

.chats .chat .messages {
    margin-top: 5px;
}

.chats .footer {
    width: 100%;
    max-height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 10px 30px 10px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(to right, #142E31, #18373a);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chats .footer button {
    width: 100%;
    max-width: 400px;
    padding: 10px 20px;
    margin-top: 0;
}

.chats .footerTabs {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: center;
    flex: 1;
    width: 100%;
}

.chats .footerTabs button {
    flex: 1;
    padding: 10px 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.chats .footerTabs button:active {
    opacity: 0.5;
}

.chats .footerTabs button svg {
    font-size: 1.5rem;
    color: #FFF;
}